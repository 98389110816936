import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UrlEnum } from '../../enum/url.enum';
import { HttpRequestService } from './../http-request/http-request.service';

@Injectable({
  providedIn: 'root',
})
export class ShohayBlogService {
  constructor(
    private httpRequestService: HttpRequestService,
    private readonly UtilitiesService: UtilitiesService
  ) {}

  public getArticleNews() {
    const headers = { Authorization: `Bearer ${environment.shohayBlogToken}` };
    return this.httpRequestService
      .get(`${UrlEnum.SHOHAY_BLOG}/articles?populate=*`, {
        skipAuth: true,
        headers,
      })
      .pipe(
        map((x: any) =>
          x.data.map((item: any) => {
            const { image, ...restOfAttributes } = item.attributes;
            return {
              id: item.id,
              imageUrl: `${
                UrlEnum.SHOHAY_STRAPI
              }${image.data.attributes.url.replace(/^\/uploads/, '')}`,
              ...restOfAttributes,
            };
          })
        )
      );
  }

  public getArticleById(id: string): Observable<any> {
    const headers = { Authorization: `Bearer ${environment.shohayBlogToken}` };
    return this.httpRequestService
      .get(`${UrlEnum.SHOHAY_BLOG}/articles/${id}`, {
        skipAuth: true,
        headers: headers,
      })
      .pipe(map((res) => this.transformResponse(res)));
  }

  private transformResponse(response: any): any {
    const { id, attributes } = response.data;
    return { id, ...attributes };
  }
}
