import { Injectable } from '@angular/core';
import { UrlEnum } from '../../enum/url.enum';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { EmergencyDonationModel } from '../../model/emergency-donation.model';

@Injectable({
  providedIn: 'root',
})
export class DonationService {
  private emergencyDonationApi = `${UrlEnum.SHOHAY_NGO}/emergency-donation`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public emergencyDonationRequest(
    body: EmergencyDonationModel
  ): Observable<any> {
    return this.utilitiesService.responseHandlerWithAlert(
      this.httpRequestService.post(
        `${this.emergencyDonationApi}/create`,
        body,
        {
          skipAuth: true,
        }
      ),
      true
    );
  }

  public getAllDonation(): Observable<any> {
    return this.httpRequestService.get(`${this.emergencyDonationApi}/get-all`, {
      skipAuth: true,
    }).pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      map((x: any) =>
        x.sort(
          this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
        )
      )
    );
  }
}
